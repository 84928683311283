import BreadCrumb from '@/components/layout/BreadCrumb/breadCrumb.vue'
import TableReports from '@/components/layout/TableReports/tableReports.vue'
import StudentsByAge from '@/components/charts/studentsByAge/studentsByAge.vue'
import Mixin from '@/views/report/dashboard/report-dashboard-mixin'

export default {
  components: {
    [BreadCrumb.name]: BreadCrumb,
    [TableReports.name]: TableReports,
    StudentsByAge
  },

  mixins: [Mixin],

  data: () => ({
    students: [],
    selected: []
  }),

  computed: {
    breadCrumb() {
      return {
        breadcrumbItems: [
          {
            text: 'Dashboard',
            href: this.$router.resolve({ name: 'Dashboard' }).href
          },
          {
            text: 'Relatórios',
            href: this.$router.resolve({ name: 'ReportStudentList' }).href
          },
          {
            text: 'Alunos por Idade'
          }
        ]
      }
    }
  },

  methods: {
    LoadStudents(students) {
      this.students = students
    },
    toggleAll() {
      if (this.selected.length) this.selected = []
      else this.selected = this.students.slice()
    },
    changeSort(column) {
      if (this.pagination.sortBy === column) {
        this.pagination.descending = !this.pagination.descending
      } else {
        this.pagination.sortBy = column
        this.pagination.descending = false
      }
    }
  }
}
